import { config } from "./config";

export const constants = {
    vacation_rentals_route: 'vacation-rentals',
    hotels_route: 'hotels',
    vacation_rentals_text: 'homes',
    hotels_text: 'hotels',
    geo_pages_slug_file_name: '[...parameters]',
    geo_pages_slug: 'parameters',
    geo_description_breakpoint: '-breakpoint-',
    blog_route: '/blog',
    privacy_policy_route: '/privacy-policy',
    help_route:'/help',
    ourteam_route:'/our-team',
    newsroom_route:'/newsroom',
    cancellation_route:'cancellation-policies',
    terms_and_conditions_route: '/terms-and-conditions',
    care_program_route: '/care-program',
    covid_route: '/coronavirus-covid-19',
    checkout_page_route: '/booking-payment',
    checkoutconfirmation_page_route: '/reservation',
    error_pages_common_start_path: '/pages/error-',
    about_us_ruote: '/about-us',
    contact_us_route: '/contact-us',
    wishlist_route: '/d/guest/lists',
    list_your_property_route: '/list-your-property',
    list_space_route: '/list_space',
    dashboard_route: '/d',

    booking_request_route: '/d',
    earnings_route: '/d',
    host_inbox_route: '/d',
    manage_listings_route: '/d/host/listing',
    // manage_listing_route: '/d',
    trips_upcoming_route: '/d/guest/my-bookings',
    listing_reservation_route: '/d/guest/my-bookings',
    inbox_route: '/d/guest/messages',
    listings_route: '/listings',
    hotel_route: '/hotel',
    search_results_route: '/sr',
    geo_page_route: '/l',
    contact_about_desk_placeholder: 'Search destinations',
    contact_about_tab_placeholder: 'Search destinations',
    common_search_placeholder: 'Search destinations',
    mobile_max_width: 600,
    tablet_max_width: 1136,
    POPULAR_CITIES_SLIDER: 'popular_cities_slider',
    POPULAR_LISTINGS_SLIDER: 'popular_listings_slider',
    default_currency_short: 'USD',
    vr_type: 'vr', // we use it to send in requests most
    hotel_type: 'hotel', // we use it to send in requests most
    guest_form_save_type: "saved",
    guest_form_clear_type: "cleared",
    // ldp dates format
    ldp_dates_format: "DD+MMM+YYYY",
    // Search results page query param passing date format
    search_results_url_dates_format: 'YYYY-MM-DD',
    // signup and signin with google and fb type
    fb_google_signin_type: 'signin',
    fb_google_signup_type: 'signup',
    amenitiesOffset: 3,
    authRefreshTokenPollSec: 25 * 60 * 1000,
    mapTooltipMaxCharLength: 50,
    pinHintCookieKey: 'cn-pin-hint',
    viewTypeHintCookieKey: 'cn-map-view-type-hint',
    checkout_tab_1: 'tab-1',
    checkout_tab_2: 'tab-2',
    propertiesPerBatch: 200,
    checkout_3d_secure_split_sign: '----',
    checkout_new_card: 'pay_with_new_card',
    defaultCurrency: {
        id: 1,
        long: 'US Dollar',
        short: 'USD',
        symbol: '$',
        rate: "1.0000"
    },
    globalDiscountPercentage: 23,
    gaTrackingId: 'G-4QV4DHNE0V', // 'G-LCBV2Q0TKX', // 'GTM-K3264ZM',
    clarityId: '5jk9v40op2',
    gaId: 'G-4QV4DHNE0V', // 'G-LCBV2Q0TKX', // 'UA-59466064-1',
    gaPixelId: 'UA-59466064-1',
    googleSiteVerification: 'FVbxNvyPaZC0e1Glx4uznBw7tIQzE7BVXGXoZAhObWM', // FrFlS4tCk0kM_1w2oDPa07qFpSqe2IoVgVVMxekYU5Q
    amenityIconBasePath: `${config.imagesUrl}/icons/`,
    googleMapsSrc: `https://maps.googleapis.com/maps/api/js?key=${config.google_api_key}&v=beta&libraries=marker,places`,
    guestTypes: {
        ADULT: 'adultCount',
        CHILD: 'childCount',
        INFANT: 'infantCount',
        ROOM: 'roomCount'
    },
    guestInput: {
        ['adultCount']: {
            MIN: 1,
            MAX: 30
        },
        ['childCount']: {
            MIN: 0,
            MAX: 10
        },
        ['infantCount']: {
            MIN: 0,
            MAX: 5
        },
        ['roomCount']: {
            MIN: 1,
            MAX: 30
        }
    },
    lengthOfStay: 43000000 * 60,
    LYPhotelRoomsCount: {
        MIN: 1,
        MAX: 5000
    },
    // onboardSchema: {
    //     srpCardHeart: {
    //         name: 'srpCardHeart',
    //         blockers: []
    //     },
    //     // srpCardAmenities: {
    //     //     name: 'srpCardAmenities',
    //     //     blockers: ['srpCardHeart']
    //     // },
    //     srpCardPOI: {
    //         name: 'srpCardPOI',
    //         blockers: ['srpCardHeart']
    //     },
    //     srpMapLandmark: {
    //         name: 'srpMapLandmark',
    //         blockers: ['srpCardHeart']
    //     },
    //     srpMapExpand: {
    //         name: 'srpMapExpand',
    //         blockers: ['srpMapLandmark']
    //     },
    //     srpFiltersAttractions: {
    //         name: 'srpFiltersAttractions',
    //         blockers: []
    //     },
    //     ldpNearby: {
    //         name: 'ldpNearby',
    //         blockers: []
    //     }
    // },
    calendarMonths: 15,
    calendarMonthsMobile: 3,
    firstDayOfWeek: 1,
    currentPositionCacheInterval: 14 * 24 * 60 * 60 * 1000,
    defaultMapMarkerPadding: 27,
    dashboardResendTokenRecaptchaKey: "6LcaMpMlAAAAACHOyJpGWEzGwj3XGBh3fW8Zskxs",
    trueString: 'true',
    falseString: 'false',
    trueStringCamelCase: 'True',
    cancellationType: {
        freeCancellation: 'Free Cancellation',
        partialCancellation: 'Partial Refund',
        nonRefundable: 'Non refundable'
    },
    crypto: {
        paymentTimeoutThresholdSeconds: 1200,
        pollTimeoutMs: 5000,
        isCryptoEnabled: config.isCryptoEnabled
    }
}