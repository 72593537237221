import React, { memo, useCallback, useEffect, useRef } from 'react';
import { setAuthData, setupLogin, validateTokenCreds } from "@/redux/actions/authActions";
import { FB_SIGNUP_SIGNIN_SUCCESS, GOOGLE_SIGNUP_SIGNIN_SUCCESS, APPLE_SIGNUP_SIGNIN_SUCCESS, LOGIN_SUCCESS, SIGNUP_SUCCESS } from "@/redux/types";
import { useDispatch, useSelector } from "react-redux";

const AuthWrapper = ({ children }) => {
    const dispatch = useDispatch();
    const authActionType = useSelector(state => state.authReducer.type);
    const timerId = useRef(null);

    const setAuthPolling = useCallback(() => {
        const fn = () => {
            if (!validateTokenCreds()) {
                // dispatch(setAuthData({ data: null, message: null }));
                dispatch(setAuthData({ data: false, message: null }));
                clearInterval(timerId.current);
            }
        };
        fn();
        timerId.current = setInterval(fn, 1000);
    }, [dispatch]);

    useEffect(() => {
        setupLogin(null, dispatch);
        setAuthPolling();
        return () => {
            clearInterval(timerId.current);
            timerId.current = null;
        };
    }, [setAuthPolling, dispatch]);

    useEffect(() => {
        if ([
            LOGIN_SUCCESS,
            SIGNUP_SUCCESS,
            GOOGLE_SIGNUP_SIGNIN_SUCCESS,
            FB_SIGNUP_SIGNIN_SUCCESS,
            APPLE_SIGNUP_SIGNIN_SUCCESS
        ].includes(authActionType)) {
            if (timerId.current !== null) {
                clearInterval(timerId.current);
                timerId.current = null;
            }
            setAuthPolling();
        }
    }, [authActionType, setAuthPolling]);

    return (
        <>
            {children}
        </>
    );
};

export default memo(AuthWrapper);
