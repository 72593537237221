import { createContext, memo } from 'react';
import { useState } from 'react';
import { DateRangeModel } from '../../DateRange';
import { GuestModel } from '../../GuestCount';
import { DestinationModel } from '../../Destination';
import AttractionsNearby from '../AttractionsNearby';
import Interests from '../Interests';

export const OmnisearchStateContext = createContext({});

const OmnisearchState = ({ children }) => {
    const step = useState(/*StepEnum.INITIAL*/);

    const destination = useState(() => new DestinationModel);
    const dates = useState(() => new DateRangeModel);
    const guests = useState(() => new GuestModel);
    const interests = useState(() => []);

    return (
        <OmnisearchStateContext.Provider
            value={{
                step,
                destination,
                dates,
                guests,
                interests
            }}
        >
            <AttractionsNearby>
                <Interests>
                    {children}
                </Interests>
            </AttractionsNearby>
        </OmnisearchStateContext.Provider>
    );
};

export default memo(OmnisearchState);
