import {cancelReservationDataWithOtp, coniformCancellation, getOTP, getReservationData, refundInormation, verifyOTP} from '@/redux/services/reservationPage';

export const INIT_RESERVATION_LIST = "INIT_RESERVATION_LIST";
export const RESERVATION_USERID = "RESERVATION_USERID";
export const VERIFY_OTP = "VERIFY_OTP";
export const INCORRECT_OTP = "INCORRECT_OTP";
export const VERIFY_CANCELLATION = "VERIFY_CANCELLATION";
export const INIT_CANCELLATION = "INIT_CANCELLATION";
export const CANCEL_RECERVATION_WITHOTP = "CANCEL_RECERVATION_WITHOTP";
export const UPDATE_MODAL_TITLE = 'UPDATE_MODAL_TITLE'
export const LOADING_OTP = 'LOADING_OTP'
//Initialize lists
export const getReservationList = (data) => {
    return { type: INIT_RESERVATION_LIST, data: data };
}

export const updateModalTitle = () => {
    return { type: UPDATE_MODAL_TITLE, data: 'data' };
}

export const cancelReservationWithOtp = () => {
    return { type: CANCEL_RECERVATION_WITHOTP, data: '' };
}

export const getReservationUserId = (data) => {
    return { type: RESERVATION_USERID, data: data };
}

export const verifyUserOtp = (data) => {
    return { type: VERIFY_OTP, data: data };
}

export const incorrectUserotp = (data) => {
    return { type: INCORRECT_OTP, data: data };
}

export const loadingTrue = () => {
    return { type: LOADING_OTP, data: '' };
}

export const verifyCancellation = (data) => {
    return { type: VERIFY_CANCELLATION, data: data };
}

export const initCancellation = () => {
    return { type: INIT_CANCELLATION , data: '' };
}

export const initCancellationModal = () => {
    return async dispatch => {
        dispatch(initCancellation());
    }
};

export const updateModalTitleService = () => {
    return async dispatch => {
        dispatch(updateModalTitle());
    }
};

export const initReservationList = (id) => {
    return async dispatch => {
        const listsAPI = await getReservationData(id);
        dispatch(getReservationList(listsAPI));
    }
};

export const reservationUserId = (id, recaptcha) => {
    return async dispatch => {
        const getUserId = await getOTP(id, recaptcha);
        dispatch(getReservationUserId(getUserId));
    }
};

export const verifyOtpCode = (id, otp) => {
    return async dispatch => {
        try{
            dispatch(loadingTrue())
            const otpResponse = await verifyOTP(id, otp);
            dispatch(verifyUserOtp(otp));
        } catch(err) {
            dispatch(incorrectUserotp(err.message));
        }
    }
};

export const verifyCancellationService = (id) => {
    return async dispatch => {
        const cancelationVerification = await coniformCancellation(id);
        dispatch(verifyCancellation(cancelationVerification));
    }
};

export const cancelReservationOtp = (id) => {
    return async dispatch => {
        const cancelationReservationOtp = await cancelReservationDataWithOtp(id);
        dispatch(cancelReservationWithOtp(cancelationReservationOtp));
    }
};


