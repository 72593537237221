import request from "@/redux/services/request";
import { config } from "@/utils/config";
import requestToken from "./requestToken";

export const getInitialData = (queryString = "") =>
  requestToken({
    url: `/${config.api_ver}/checkout/checkout${queryString}`,
    method: "GET",
    dev3: true,
  });

export const getSideWidgetData = (queryString = "") =>
  requestToken({
    url: `/${config.api_ver}/checkoutpage/side_widget_detail${queryString}`,
    method: "GET",
  });

export const getCheckoutConfirmationData = (queryString = "") =>
  request({
    url: `/${config.api_ver}/checkout/checkout_confirmation?booking_id=${queryString}`,
    method: "GET",
  });

export const getCheckoutCardsData = (queryString = "") =>
  requestToken({
    url: `/${config.api_ver}/checkout/create_payment_method${queryString}`,
    method: "GET",
    dev3: true,
  });

export const confirmPaymentMethod = (queryString = "") =>
  request({
    url: `/${config.api_ver}/checkout/confirm_payment_method${queryString}`,
    method: "GET",
  });
// for anonymous users rendered server side
export const getCheckoutGuestData = (queryString = "") =>
  request({
    url: `/${config.api_ver}/checkoutpage/guest_detail${'?' + queryString}`,
    method: "GET",
  });
// for logged in user on client side
export const getCheckoutGuestDataWithUser = (queryString = "") =>
  requestToken({
    url: `/${config.api_ver}/checkoutpage/guest_detail${'?' + queryString}`,
    method: "GET",
  });

export const confirmBooking = (payload) =>
  requestToken({
    url: `/${config.api_ver}/checkoutpage/confirm_booking`,
    method: "POST",
    data: payload,
    headers: { "Content-Type": "multipart/form-data" },
  });

export const deleteCard = (cardId = "") =>
  requestToken({
    url: `/${config.api_ver}/delete_card/${cardId}`,
    method: "DELETE",
  });

export const validatVoucher = (code = "", booking_id = "", token ="") =>
  requestToken({
    url: `/${config.api_ver}/discount_voucher/validate/${code}/${booking_id}`,
    method: "GET",
    headers:  { 'X-Authorization': token && `Bearer ${token}` }
  });

export const revokeVoucher = (booking_id = "") =>
  request({
    url: `/${config.api_ver}/discount_voucher/revoke/${booking_id}`,
    method: "GET",
  });