import { INIT_RESERVATION_LIST,  RESERVATION_USERID, VERIFY_OTP, VERIFY_CANCELLATION, INIT_CANCELLATION, CANCEL_RECERVATION_WITHOTP, UPDATE_MODAL_TITLE, INCORRECT_OTP, LOADING_OTP  } from '@/redux/actions/reservationActions';

const initialState = {
  data:null,
  modalStep: 'First step',
  modalWidth: '506px',
  headerTitle: '', 
  verifyLoading: false
};

const reservationReducer = (state = initialState, action) => {
    switch (action.type) {
        case INIT_RESERVATION_LIST:
            return {
                ...state,
                data: action.data.data
            }
        case RESERVATION_USERID:
                return {
                    ...state,
                    userId: action.data.data.user_id,
                    modalStep: 'OTP step',
                    email: action.data.data.email,
                    modalWidth: '402px',
                    verifyLoading: false,
                    errorMessage: '',
                }
        case VERIFY_OTP:
                return {
                    ...state,
                    modalStep: 'Coniformation step',
                    otp: action.data,
                    modalWidth: '486px',
                }
        case VERIFY_CANCELLATION:
                return {
                    ...state,
                    modalStep: 'Reason step',
                    reason: action.data.data.cancellation_reasons,
                    refundInfo: action.data.data.refund_details,
                    receptUrl: action.data.data.get_receipt_url,
                    modalWidth: '639px',
                    headerTitle: 'Cancellation reason',
                    errorMessage: '',
                } 
        case UPDATE_MODAL_TITLE: 
                return {
                    ...state,
                    headerTitle: 'Refund review'
                } 
        case CANCEL_RECERVATION_WITHOTP:
                return {
                    ...state,
                    modalStep: 'Sucess step',
                    reason: action.data,
                    headerTitle: 'Cancel booking',
                    cancelationStatus: 'Cancelled'
                } 
        case LOADING_OTP: 
                return {
                    ...state,
                    verifyLoading: true
                }
        case INCORRECT_OTP:
                return {
                    ...state,
                   errorMessage: action.data,
                   verifyLoading: false,
                }  
        case INIT_CANCELLATION:
                return {
                    ...state,
                    data:null,
                    modalStep: 'First step',
                    modalWidth: '506px',
                    headerTitle: '', 
                    verifyLoading: false
                }
        default:
            return state;
    }
};

export default reservationReducer;