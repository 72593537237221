// Action types definations

export const GET_LISTING_COUNTRY_LOAD = "GET_LISTING_COUNTRY_LOAD";
export const GET_LISTING_COUNTRY_SUCCESS = "GET_LISTING_COUNTRY_SUCCESS";
export const GET_LISTING_COUNTRY_ERR = "GET_LISTING_COUNTRY_ERR";

export const GET_LISTING_WHERE_LOAD = "GET_LISTING_WHERE_LOAD";
export const GET_LISTING_WHERE_SUCCESS = "GET_LISTING_WHERE_SUCCESS";
export const GET_LISTING_WHERE_ERR = "GET_LISTING_WHERE_ERR";

export const GET_LISTING_ACCESSIBLE_LOAD = "GET_LISTING_ACCESSIBLE_LOAD";
export const GET_LISTING_ACCESSIBLE_SUCCESS = "GET_LISTING_ACCESSIBLE_SUCCESS";
export const GET_LISTING_ACCESSIBLE_ERR = "GET_LISTING_ACCESSIBLE_ERR";
export const GET_LISTING = "GET_LISTING";

/** Global Types
 * @param Currency
 */
export const GET_CURRENCIES_LOAD = "GET_CURRENCIES_LOAD";
export const GET_CURRENCIES_SUCCESS = "GET_CURRENCIES_SUCCESS";
export const GET_CURRENCIES_ERR = "GET_CURRENCIES_ERR";

export const SET_CURRENCY = "SET_CURRENCY";

export const REGISTRY_ADD_COMMAND = 'REGISTRY_ADD_COMMAND';
export const REGISTRY_UPDATE_COMMAND = 'REGISTRY_UPDATE_COMMAND';
export const REGISTRY_REMOVE_COMMAND = 'REGISTRY_REMOVE_COMMAND';

/**
 * Types related to global Notifications
 */

export const SUCCESS_NOTIFICATION = 'success_notification';
export const ERROR_NOTIFICATION = 'error_notification';
export const INFO_NOTIFICATION = 'info_notification';

export const URL_PATH = 'url_path';

/**
 * Auth Types
 * @param Login,
 * @param Signup,
 * @param Logout,
 * @param RefreshToken
 * @param FbSignupIn
 */

export const SET_AUTH_DATA = 'SET_AUTH_DATA';
export const RESET_DATA = "RESET_DATA";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_LOAD = "LOGIN_LOAD";

export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
export const SIGNUP_LOAD = "SIGNUP_LOAD";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const LOGOUT_FAIL = "LOGOUT_FAIL"
export const LOGOUT_LOAD = "LOGOUT_LOAD"

export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS"
export const REFRESH_TOKEN_FAIL = "REFRESH_TOKEN_FAIL"
export const REFRESH_TOKEN_LOAD = "REFRESH_TOKEN_LOAD"

export const FB_SIGNUP_SIGNIN_SUCCESS = "FB_SIGNUP_SIGNIN_SUCCESS"
export const FB_SIGNUP_SIGNIN_FAIL = "FB_SIGNUP_SIGNIN_FAIL"
export const FB_SIGNUP_SIGNIN_LOAD = "FB_SIGNUP_SIGNIN_LOAD"

export const GOOGLE_SIGNUP_SIGNIN_SUCCESS = "GOOGLE_SIGNUP_SIGNIN_SUCCESS"
export const GOOGLE_SIGNUP_SIGNIN_FAIL = "GOOGLE_SIGNUP_SIGNIN_FAIL"
export const GOOGLE_SIGNUP_SIGNIN_LOAD = "GOOGLE_SIGNUP_SIGNIN_LOAD"

export const APPLE_SIGNUP_SIGNIN_SUCCESS = "APPLE_SIGNUP_SIGNIN_SUCCESS"
export const APPLE_SIGNUP_SIGNIN_FAIL = "APPLE_SIGNUP_SIGNIN_FAIL"
export const APPLE_SIGNUP_SIGNIN_LOAD = "APPLE_SIGNUP_SIGNIN_LOAD"

export const VERIFY_CODE_SUCCESS = "VERIFY_CODE_SUCCESS";
export const VERIFY_CODE_FAIL = "VERIFY_CODE_FAIL";
export const VERIFY_CODE_LOAD = "VERIFY_CODE_LOAD";

export const RESEND_VERIFY_CODE_SUCCESS = "RESEND_VERIFY_CODE_SUCCESS";
export const RESEND_VERIFY_CODE_FAIL = "RESEND_VERIFY_CODE_FAIL";
export const RESEND_VERIFY_CODE_LOAD = "RESEND_VERIFY_CODE_LOAD";

export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";
export const FORGOT_PASSWORD_LOAD = "FORGOT_PASSWORD_LOAD";

/**
 * Search results page types
 */
export const ARE_SEARCH_FILTERS_VALID = 'ARE_SEARCH_FILTERS_VALID';
export const SEARCH_RESULTS_MODULE_ACTIVE = 'SEARCH_RESULTS_MODULE_ACTIVE';

export const SEARCH_RESULTS_RESOLUTION_TYPE = 'RESOLUTION_TYPE';
export const SEARCH_RESULTS_VIEW_STATE = 'VIEW_STATE';

export const SEARCH_RESULTS_INIT_SEARCH_FILTERS = 'SEARCH_RESULTS_INIT_SEARCH_FILTERS';

export const SEARCH_RESULTS_CHANGE_SEARCH_INTEND = 'SEARCH_RESULTS_CHANGE_SEARCH_INTEND';

export const SEARCH_RESULTS_SEARCH_RESULT_CHANGE = 'SEARCH_RESULTS_SEARCH_RESULT_CHANGE';
export const SEARCH_RESULTS_SEARCH_RESULT_FOCUS_CHANGE = 'SEARCH_RESULTS_SEARCH_RESULT_FOCUS_CHANGE';

export const SEARCH_RESULTS_DATEPICKER_CHANGE = 'SEARCH_RESULTS_DATEPICKER_CHANGE';
export const SEARCH_RESULTS_DATEPICKER_FOCUS_CHANGE = 'SEARCH_RESULTS_DATEPICKER_FOCUS_CHANGE';

export const SEARCH_RESULTS_GUEST_SELECTOR_CHANGE = 'SEARCH_RESULTS_GUEST_SELECTOR_CHANGE';
export const SEARCH_RESULTS_GUEST_SELECTOR_FOCUS_CHANGE = 'SEARCH_RESULTS_GUEST_SELECTOR_FOCUS_CHANGE';

export const SEARCH_RESULTS_AMENITIES_CONTAINER_OFFSET_HEIGHT_CHANGE = 'SEARCH_RESULTS_AMENITIES_CONTAINER_OFFSET_HEIGHT_CHANGE';

export const SEARCH_RESULTS_SET_FILTERS_BAR_COLLAPSED = 'SEARCH_RESULTS_SET_FILTERS_BAR_COLLAPSED';

export const SEARCH_RESULTS_FILTERS_BUDGET = 'SEARCH_RESULTS_FILTERS_BUDGET';

export const SEARCH_RESULTS_FILTERS_PROPERTY_TYPE = 'SEARCH_RESULTS_FILTERS_PROPERTY_TYPE';
export const SEARCH_RESULTS_FILTERS_STAR_RATING = 'SEARCH_RESULTS_FILTERS_STAR_RATING';
export const SEARCH_RESULTS_FILTERS_AMENITIES = 'SEARCH_RESULTS_FILTERS_AMENITIES';
export const SEARCH_RESULTS_FILTERS_RECOMMENDED = 'SEARCH_RESULTS_FILTERS_RECOMMENDED';
export const SEARCH_RESULTS_FILTERS_THINGS_TO_DO = 'SEARCH_RESULTS_FILTERS_THINGS_TO_DO';
export const SEARCH_RESULTS_FILTERS_ACTIVITIES = 'SEARCH_RESULTS_FILTERS_ACTIVITIES';
export const SEARCH_RESULTS_FILTERS_REVIEW_SCORE = 'SEARCH_RESULTS_FILTERS_REVIEW_SCORE';
export const SEARCH_RESULTS_FILTERS_PARTNERS = 'SEARCH_RESULTS_FILTERS_PARTNERS';
export const SEARCH_RESULTS_FILTERS_HOTEL_CHAIN = 'SEARCH_RESULTS_FILTERS_HOTEL_CHAIN';
export const SEARCH_RESULTS_FILTERS_ATTRACTIONS = 'SEARCH_RESULTS_FILTERS_ATTRACTIONS';
export const SEARCH_RESULTS_FILTERS_BEDROOMS_COUNT = 'SEARCH_RESULTS_FILTERS_BEDROOMS_COUNT';
export const SEARCH_RESULTS_FILTERS_BEDS_COUNT = 'SEARCH_RESULTS_FILTERS_BEDS_COUNT';
export const SEARCH_RESULTS_FILTERS_BATHROOMS_COUNT = 'SEARCH_RESULTS_FILTERS_BATHROOMS_COUNT';

export const SEARCH_RESULTS_SET_YOUR_BUDGET = 'SEARCH_RESULTS_SET_YOUR_BUDGET';
export const SEARCH_RESULTS_CHANGE_SEARCH_FILTERS_STATE = 'SEARCH_RESULTS_CHANGE_SEARCH_FILTERS_STATE';

export const SEARCH_RESULTS_HOVER_LISTING_PROPERTY_CARD = 'SEARCH_RESULTS_HOVER_PROPERTY_CARD';

export const SEARCH_RESULTS_FILTERS_SORT_BY_CHANGE = 'SEARCH_RESULTS_FILTERS_SORT_BY_CHANGE';

export const SEARCH_RESULTS_API_GET_SEARCH_RESULTS_LOADING = 'SEARCH_RESULTS_API_GET_SEARCH_RESULTS_LOADING';
export const SEARCH_RESULTS_API_GET_SEARCH_RESULTS_SUCCESS = 'SEARCH_RESULTS_API_GET_SEARCH_RESULTS_SUCCESS';
export const SEARCH_RESULTS_API_GET_SEARCH_RESULTS_ERROR = 'SEARCH_RESULTS_API_GET_SEARCH_RESULTS_ERROR';

export const SEARCH_RESULTS_SET_PAGE_NUMBER = 'SEARCH_RESULTS_SET_PAGE_NUMBER';

export const SEARCH_RESULTS_RESET_SEARCH_RESULTS_STORE = 'SEARCH_RESULTS_RESET_SEARCH_RESULTS_STORE';

export const SEARCH_RESULTS_SET_BROWSING_HISTORY_SEARCH_DATA = 'SEARCH_RESULTS_SET_BROWSING_HISTORY_SEARCH_DATA';

export const SEARCH_RESULTS_SET_VIEW_MODE = 'SEARCH_RESULTS_SET_VIEW_MODE';

export const SEARCH_RESULTS_BOUNDS_SEARCH_TOGGLE = 'SEARCH_RESULTS_BOUNDS_SEARCH_TOGGLE';
export const MAP_EXPAND_TOGGLE = 'MAP_EXPAND_TOGGLE';

export const SEARCH_RESULTS_SECONDARY_FILTERS_INTERACTED = 'SEARCH_RESULTS_SECONDARY_FILTERS_INTERACTED';

export const SEARCH_RESULTS_TOGGLE_MAP_STYLING_BY_POI_TYPE = 'SEARCH_RESULTS_TOGGLE_MAP_STYLING_BY_POI_TYPE';

export const SEARCH_RESULTS_UPDATE_PROPERTY = 'SEARCH_RESULTS_UPDATE_PROPERTY';

export const SEARCH_RESULTS_RESET_AVAILABILITY_NEEDED = 'SEARCH_RESULTS_RESET_AVAILABILITY_NEEDED';

// NEW UPDATED STREAM API
export const SEARCH_RESULTS_UPDATE_RATE = 'SEARCH_RESULTS_UPDATE_RATE';
export const SEARCH_RESULTS_UPDATE_META = 'SEARCH_RESULTS_UPDATE_META';

// NEIGHBORHOOD
export const SEARCH_RESULTS_FILTERS_NEIGHBOURHOODS = 'SEARCH_RESULTS_FILTERS_NEIGHBOURHOODS';

// LANDMARKS
export const SEARCH_RESULTS_FILTERS_LANDMARKS = 'SEARCH_RESULTS_FILTERS_LANDMARKS';
export const SEARCH_RESULTS_API_GET_LANDMARKS_LOADING = 'SEARCH_RESULTS_API_GET_LANDMARKS_LOADING';
export const SEARCH_RESULTS_API_GET_LANDMARKS_SUCCESS = 'SEARCH_RESULTS_API_GET_LANDMARKS_SUCCESS';
export const SEARCH_RESULTS_API_GET_LANDMARKS_ERROR = 'SEARCH_RESULTS_API_GET_LANDMARKS_ERROR';

// BE FILTER IMPLEMENTATION
export const SEARCH_RESULTS_DATA_AVAILABLE = 'SEARCH_RESULTS_DATA_AVAILABLE';
export const SEARCH_RESULTS_SET_FILTERS = 'SEARCH_RESULTS_SET_FILTERS';
export const SEARCH_RESULTS_DATA_LOADING = 'SEARCH_RESULTS_DATA_LOADING';
export const SEARCH_RESULTS_DATA_ERROR = 'SEARCH_RESULTS_DATA_ERROR';
export const SEARCH_RESULTS_REQUEST_TYPE = 'SEARCH_RESULTS_REQUEST_TYPE';

/**
 * Product Detail
 */
export const PRODUCT_UPDATE = 'PRODUCT_UPDATE';
export const PRODUCT_ROOM_AMENTIES_PROGRESS = 'PRODUCT_ROOM_AMENTIES_PROGRESS';
export const PRODUCT_ROOM_AMENTIES = 'PRODUCT_ROOM_AMENTIES';
export const WISHLIST_PRODUCT_SIMILAR_LISTING = 'WISHLIST_PRODUCT_SIMILAR_LISTING';
export const PRODUCT_RESET = 'PRODUCT_RESET';
export const PRODUCT_NEARBY_POIS_AVAILABLE = 'PRODUCT_NEARBY_POIS_AVAILABLE';

export const PRODUCT_MODAL_REGISTRY_ADD_COMMAND = 'PRODUCT_MODAL_REGISTRY_ADD_COMMAND';
export const PRODUCT_MODAL_REGISTRY_UPDATE_COMMAND = 'PRODUCT_MODAL_REGISTRY_UPDATE_COMMAND';
export const PRODUCT_MODAL_REGISTRY_REMOVE_COMMAND = 'PRODUCT_MODAL_REGISTRY_REMOVE_COMMAND';

/**
 * List New Property
 */
export const NEW_PRORDUCT_RESET = 'NEW_PRORDUCT_RESET';
export const NEW_PRORDUCT_BOOTSTRAP = 'NEW_PRORDUCT_BOOTSTRAP';
export const NEW_PRORDUCT_UPDATE = 'NEW_PRORDUCT_UPDATE';
export const NEW_PRORDUCT_UPDATE_STEP = 'NEW_PRORDUCT_UPDATE_STEP';


/**
 * Theme pages
 */
 export const THEME_PAGE_RESET = 'THEME_PAGE_RESET';
 export const THEME_PAGE_UDPATE = 'THEME_PAGE_UPDATE';


 /**
  * Geo Pages Types
  * @param PopularCities,
  * @param Temperature,
  * @param PopulareVacationRentals,
  * @param Description,
  * @param Faq
  */

export const GET_POPULAR_CITIES_LOAD = "GET_POPULAR_CITIES_LOAD";
export const GET_POPULAR_CITIES_SUCCESS = "GET_POPULAR_CITIES_SUCCESS";
export const GET_POPULAR_CITIES_ERR = "GET_POPULAR_CITIES_ERR";

export const GET_TEMPERATURE_LOAD = "GET_TEMPERATURE_LOAD";
export const GET_TEMPERATURE_SUCCESS = "GET_TEMPERATURE_SUCCESS";
export const GET_TEMPERATURE_ERR = "GET_TEMPERATURE_ERR";

export const GET_POPULAR_VACATION_RENTALS_LOAD = "GET_POPULAR_VACATION_RENTALS_LOAD";
export const GET_POPULAR_VACATION_RENTALS_SUCCESS = "GET_POPULAR_VACATION_RENTALS_SUCCESS";
export const GET_POPULAR_VACATION_RENTALS_ERR = "GET_POPULAR_VACATION_RENTALS_ERR";

export const GET_DESCRIPTION_LOAD = "GET_DESCRIPTION_LOAD";
export const GET_DESCRIPTION_SUCCESS = "GET_DESCRIPTION_SUCCESS";
export const GET_DESCRIPTION_ERR = "GET_DESCRIPTION_ERR";

export const GET_FAQ_LOAD = "GET_FAQ_LOAD";
export const GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS";
export const GET_FAQ_ERR = "GET_FAQ_ERR";

export const GET_CURRENT_LOAD = "GET_CURRENT_LOAD";
export const GET_CURRENT_SUCCESS = "GET_CURRENT_SUCCESS";
export const GET_CURRENT_ERR = "GET_CURRENT_ERR";

export const SET_GEO_MAP_EXPAND_STATUS = 'set_geo_map_expand_status'


/* Geo location types */
export const GEO_CODE_LOAD = 'GEO_CODE_LOAD'
export const GEO_CODE_SUCCESS = 'GEO_CODE_SUCCESS'
export const GEO_CODE_ERROR = 'GEO_CODE_ERROR'
export const GEO_CODE_RESET = 'GEO_CODE_RESET';

/**
 * @param CheckoutPageSideWidget
 */
export const GET_CHECKOUT_SIDE_WIDGET_LOAD = 'GET_CHECKOUT_SIDE_WIDGET_LOAD'
export const GET_CHECKOUT_SIDE_WIDGET_SUCCESS = 'GET_CHECKOUT_SIDE_WIDGET_SUCCESS'
export const GET_CHECKOUT_SIDE_WIDGET_ERR = 'GET_CHECKOUT_SIDE_WIDGET_ERR'

/**
 * @param CheckoutPageCreditCards
 */
export const GET_CHECKOUT_CARDS_LOAD = 'GET_CHECKOUT_CARDS_LOAD'
export const GET_CHECKOUT_CARDS_SUCCESS = 'GET_CHECKOUT_CARDS_SUCCESS'
export const GET_CHECKOUT_CARDS_ERR = 'GET_CHECKOUT_CARDS_ERR'

/**
 * @param CreateCheckoutPageCreditCards
 */
export const CREATE_CHECKOUT_CARD_LOAD = 'CREATE_CHECKOUT_CARD_LOAD'
export const CREATE_CHECKOUT_CARD_SUCCESS = 'CREATE_CHECKOUT_CARD_SUCCESS'
export const CREATE_CHECKOUT_CARD_ERR = 'CREATE_CHECKOUT_CARD_ERR'

/**
 * @param getCheckoutPageGuestDetails
 */
export const GET_CHECKOUT_GUEST_DETAIL_LOAD = 'GET_CHECKOUT_GUEST_DETAIL_LOAD'
export const GET_CHECKOUT_GUEST_DETAIL_SUCCESS = 'GET_CHECKOUT_GUEST_DETAIL_SUCCESS'
export const GET_CHECKOUT_GUEST_DETAIL_ERR = 'GET_CHECKOUT_GUEST_DETAIL_ERR'

/**
 * @param sendConfirmBookingInformation
 */
export const CHECKOUT_CONFIRM_BOOKING_LOAD = 'CHECKOUT_CONFIRM_BOOKING_LOAD'
export const CHECKOUT_CONFIRM_BOOKING_SUCCESS = 'CHECKOUT_CONFIRM_BOOKING_SUCCESS'
export const CHECKOUT_CONFIRM_BOOKING_ERR = 'CHECKOUT_CONFIRM_BOOKING_ERR'


/**
 * @param set checkout pages data
 */

export const CHECKOUT_SET_CARDS = 'CHECKOUT_SET_CARDS'
export const CHECKOUT_SET_SELECTED_CARD = 'CHECKOUT_SET_SELECTED_CARD'
export const CHECKOUT_GUEST_DATA = 'CHECKOUT_GUEST_DATA'


/**
 * @param set checkout pages data
 */

export const CHECKOUT_INITIAL_DATA_LOAD = 'CHECKOUT_INITIAL_DATA_LOAD'
export const CHECKOUT_INITIAL_DATA_SUCCESS = 'CHECKOUT_INITIAL_DATA_SUCCESS'
export const CHECKOUT_INITIAL_DATA_ERR = 'CHECKOUT_INITIAL_DATA_ERR'

/**
 * checkout 3d secure popup status
 */
export const _3DS_POPUP_STATUS = '_3DS_POPUP_STATUS';

/* Geo Nearby Location */
export const GEO_NEAR_LOAD = 'GEO_NEAR_LOAD'
export const GEO_NEAR_SUCCESS = 'GEO_NEAR_SUCCESS'
export const GEO_NEAR_ERROR = 'GEO_NEAR_ERROR'

/* Onboarding Tooltips */
export const ONBOARD_INIT = 'ONBOARD_INIT';
export const ONBOARD_SET_VISIBILITY = 'ONBOARD_SET_VISIBILITY';
export const ONBOARD_REMOVE_MSG = 'ONBOARD_REMOVE_MSG';

/* Device detect */
export const DEVICE_UPDATE = 'DEVICE_UPDATE';
