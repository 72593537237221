import moment from 'moment';
import * as types from '@/redux/types';
import * as authService from '@/redux/services/authApi';
import * as listActions from "@/redux/actions/wishListActions";
import { _checkValidStatus } from '@/src/utils/globalValidations';
import { constants } from '@/src/utils/constants';
import { DASHBOARD_RESET } from '../../containers/Dashboard/redux/types/dashboardTypes';
import { getUserProfile } from "@/containers/Dashboard/redux/actions/dashboardProfileActions";

export const setAuthData = ({ data, message, type = types.SET_AUTH_DATA }) => ({
    type,
    data,
    message
})

/**
 * refresTokenAction
 */
export const refreshTokenAction = () => async dispatch => {
    dispatch({ type: types.REFRESH_TOKEN_LOAD });

    let actionPayload = {
        type: types.REFRESH_TOKEN_FAIL,
        data: null
    };

    try {
        const res = await authService.refreshToken();
        if (_checkValidStatus(res)) {
            setupLogin(res.data, dispatch);
            actionPayload = { type: types.REFRESH_TOKEN_SUCCESS, data: removeToken({...res.data}), message: res.message };
            await getUserProfile(dispatch, res.data._id)
        }else {
            setupLogout();
            actionPayload = { ...actionPayload, message: res.message };
        }

        return dispatch(actionPayload); 
    } catch (err) {
        setupLogout(); 
        return dispatch({ ...actionPayload, message: err.message }); 
    }
    
};

/**
 * loginAction
 * @param {EMAIL, PASSWORD} data
 */
export const loginAction = data => {
    return async dispatch => {
        dispatch({ type: types.LOGIN_LOAD });
        try {
            const res = await authService.login(data);
            if(_checkValidStatus(res)){
                setupLogin(res.data, dispatch);
                dispatch(listActions.initWishList());
                return dispatch({ type: types.LOGIN_SUCCESS, data: removeToken({...res.data}), message: res.message });
            }
            dispatch({ type: types.LOGIN_FAIL, data: false, message: res.message,user_id: res?.user_id, statusCode: res?.statusCode, payload: [data?.country_calling_code.slice(1), data?.phone]});
        }catch (err) {
            dispatch({ type: types.LOGIN_FAIL, data: false, message: err.message });
        }
    }
}

export const resetAuth = () => {
    return async dispatch => {
         return dispatch({ type: types.RESET_DATA, data: null, message: null });
    }
}

/**
 * singupAction
 * @param {EMAIL, PASSWORD} data
 */
export const signupAction = data => {
    return async dispatch => {
        dispatch({ type: types.SIGNUP_LOAD });
        try {
            const res = await authService.signup(data);
            if(_checkValidStatus(res)){
                setupLogin(res.data, dispatch);
                dispatch(listActions.initWishList());
                return dispatch({ type: types.SIGNUP_SUCCESS, data: removeToken({...res.data}), message: res.message });
            }
            dispatch({ type: types.SIGNUP_FAIL, data: false, message: res.message });
        }catch (err) {
            dispatch({ type: types.SIGNUP_FAIL, data: false, message: err.message });
        }
    }
}


/**
 * verifyCodeAction
 */
 export const verifyCodeAction = data => {
    return async dispatch => {
        dispatch({ type: types.VERIFY_CODE_LOAD });
        try {
            const res = await authService.verifyCode(data);
            if (_checkValidStatus(res)) {
                setupLogin(res.data, dispatch);
                dispatch(listActions.initWishList());
                return dispatch({ type: types.VERIFY_CODE_SUCCESS, data: removeToken({...res.data}), message: res.message });
            }
            dispatch({ type: types.VERIFY_CODE_FAIL, data: false, message: res.message });
        } catch(err) {
            dispatch({ type: types.VERIFY_CODE_FAIL, data: false, message: err.message });
        }
    }
}


/**
 * resendVerifyCodeAction
 */
 export const resendVerifyCodeAction = data => {
    return async dispatch => {
        dispatch({ type: types.RESEND_VERIFY_CODE_LOAD });
        try {
            const res = await authService.resendVerifyCode(data);
            if (_checkValidStatus(res)) {
                return dispatch({ type: types.RESEND_VERIFY_CODE_SUCCESS, data: removeToken({...res.data}), message: res.message });
            }
            dispatch({ type: types.RESEND_VERIFY_CODE_FAIL, data: false, message: res.message });
        } catch(err) {
            dispatch({ type: types.RESEND_VERIFY_CODE_FAIL, data: false, message: err.message });
        }
    }
}


/**
 * forgotPasswordAction
 */
 export const forgotPasswordAction = data => {
    return async dispatch => {
        dispatch({ type: types.FORGOT_PASSWORD_LOAD });
        try {
            const res = await authService.forgotPassword(data);
            if (_checkValidStatus(res)) {
                return dispatch({ type: types.FORGOT_PASSWORD_SUCCESS, data: false, message: res.message });
            }
            dispatch({ type: types.FORGOT_PASSWORD_FAIL, data: false, message: res.message });
        } catch(err) {
            dispatch({ type: types.FORGOT_PASSWORD_FAIL, data: false, message: err.message });
        }
    }
}


/**
 * logoutAction
 */
export const logoutAction = () => {
    return async dispatch => {
        dispatch({ type: types.LOGOUT_LOAD });
        try {
            const res = await authService.logout();
            if(_checkValidStatus(res)){
                dispatch(listActions.notLoggedListSet());
                return dispatch({ type: types.LOGOUT_SUCCESS, data: false, message: res.message });
            }
            return dispatch({ type: types.LOGOUT_FAIL, data: false, message: res.message });
        }catch (err) {
            dispatch({ type: types.LOGOUT_FAIL, data: false, message: err.message });
        } finally {
            setupLogout();
            return dispatch({ type: DASHBOARD_RESET });
        }
    }
}


/**
 * FbSignupSigninAction
 */
export const FbSignupSigninAction = (data) => {
    return async dispatch => {
        dispatch({ type: types.FB_SIGNUP_SIGNIN_LOAD });
        try {
            const res = await authService.fbSignUpIn(data);
            if(_checkValidStatus(res)){
                setupLogin(res.data, dispatch);
                dispatch(listActions.initWishList());
                return dispatch({ type: types.FB_SIGNUP_SIGNIN_SUCCESS, data: removeToken({...res.data}), message: res.message });
            }
            dispatch({ type: types.FB_SIGNUP_SIGNIN_FAIL, data: false, message: res.message });
        }catch (err) {
            dispatch({ type: types.FB_SIGNUP_SIGNIN_FAIL, data: false, message: err.message });
        }
    }
}

/**
 * GoogleSignupSigninAction
 */
export const GoogleSignupSigninAction = (data) => {
    return async dispatch => {
        dispatch({ type: types.GOOGLE_SIGNUP_SIGNIN_LOAD });
        try {
            const res = await authService.googleSignUpIn(data);
            if(_checkValidStatus(res)){
                setupLogin(res.data, dispatch);
                dispatch(listActions.initWishList());
                return dispatch({ type: types.GOOGLE_SIGNUP_SIGNIN_SUCCESS, data: removeToken({...res.data}), message: res.message });
            }
            dispatch({ type: types.GOOGLE_SIGNUP_SIGNIN_FAIL, data: false, message: res.message });
        }catch (err) {
            dispatch({ type: types.GOOGLE_SIGNUP_SIGNIN_FAIL, data: false, message: err.message });
        }
    }
}

/**
 * FbSignupSigninAction
 */
 export const AppleSignupSigninAction = (data) => {
    return async dispatch => {
        dispatch({ type: types.APPLE_SIGNUP_SIGNIN_LOAD });
        try {
            const res = await authService.appleSignUpIn(data);
            if(_checkValidStatus(res)){
                setupLogin(res.data, dispatch);
                dispatch(listActions.initWishList());
                return dispatch({ type: types.APPLE_SIGNUP_SIGNIN_SUCCESS, data: removeToken({...res.data}), message: res.message });
            }
            dispatch({ type: types.APPLE_SIGNUP_SIGNIN_FAIL, data: false, message: res.message });
        }catch (err) {
            dispatch({ type: types.APPLE_SIGNUP_SIGNIN_FAIL, data: false, message: err.message });
        }
    }
}


export function setupLogin(data, dispatch) {
    let expiryTime = localStorage.getItem('expiryTime');

    if (data instanceof Object) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('refreshToken', data.refresh_token);
        localStorage.setItem('expiryTime', data.exp);
        localStorage.setItem('userData', JSON.stringify(data));
        expiryTime = data.exp;
    }

    const dateNow = new Date();
    const momentExpireDate = validateTokenCreds(expiryTime, dateNow);

    if (!momentExpireDate) {
        return;
    }

    if (!data) {
        dispatch(refreshTokenAction());
    }

    clearTimeout(setupLogin.refreshTimerId);
    setupLogin.refreshTimerId = setTimeout(() => {
        setupLogin(null, dispatch);
    }, /*(momentExpireDate.diff(dateNow, 'seconds') - 1)*/constants.authRefreshTokenPollSec); // => 360 * 1000
}

export function validateTokenCreds(exp = localStorage.getItem('expiryTime'), dateNow = new Date()) {
    if (Number.isNaN(exp) || !exp || !(localStorage.getItem('token') && localStorage.getItem('refreshToken'))) {
        return setupLogout();
    }

    const momentExpireDate = moment(new Date(exp * 1000));
    if (!momentExpireDate.isValid()) {
        return setupLogout();
    }

    if (momentExpireDate.subtract(1, 'second').isBefore(dateNow)) {
        return setupLogout();
    }

    return momentExpireDate;
}

function setupLogout() {
    clearTimeout(setupLogin.refreshTimerId)
    delete setupLogin.refreshTimerId;

    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('expiryTime');
    localStorage.removeItem('userData');
}

function removeToken(data) {
    delete data['token'];
    delete data['refresh_token'];
    delete data['exp'];
    return data;
}
