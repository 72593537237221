import { DestinationTypeEnum } from "@/static/contants";
import { aggregateLocationByType, normalizeLocation } from "@/utils/locationNormalizers";
import { useCallback } from "react";
import useApi from "./useApi";

export default function useUserLocation(checkLocation = false) {
    const { withLocation, withNormalizedLocation } = useApi();

    const getLocationType = useCallback(({ city, state, country }) => {
        let destinationType;
        if (country) {
            destinationType = DestinationTypeEnum.COUNTRY;
        }
        if (state) {
            destinationType = DestinationTypeEnum.STATE;
        }
        if (city) {
            destinationType = DestinationTypeEnum.CITY;
        }
        return destinationType;
    }, []);

    const getName = withNormalizedLocation(({ city, state, country }) => (city || state || country));

    const getAddress = withNormalizedLocation(({ city, state, country }) =>
        aggregateLocationByType({
            type: getLocationType({ city, state, country }),
            action: normalizeLocation
        })(`${city}, ${state}, ${country}`));

    /**
     * locationObject: { city, state, country }
     */
    const getUserLocation = withLocation(locationObject => ({
        ...locationObject,
        type: getLocationType(locationObject),
        address: getAddress(locationObject),
        name: getName(locationObject)
    }), checkLocation);

    return {
        getUserLocation,
        getLocationType,
        getAddress,
        getName,
    };
}
