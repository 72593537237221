import { useCallback } from 'react';
import { getGuestUnitsByNumber, guestAgeTypes, getGuestUnit, childAgeValidator } from '@/components/formComponents/GuestSelector/guestSelectorHelpers';
import { convertToNum } from '@/src/utils/globalFunc';

export default function useGetGuests(asHook = true) {
    const fn = ({ adults, children, infants }, isVR) =>
        isVR ? [
            ...getGuestUnitsByNumber(convertToNum(adults, 1), guestAgeTypes.ADULT),
            ...getGuestUnitsByNumber(convertToNum(children, 0), guestAgeTypes.CHILD),
            ...getGuestUnitsByNumber(convertToNum(infants, 0), guestAgeTypes.INFANT)
        ] : [
            ...getGuestUnitsByNumber(convertToNum(adults, 1), guestAgeTypes.ADULT),
            // ...getGuestUnitsByNumber(convertToNum(children, 0) + convertToNum(infants, 0), guestAgeTypes.CHILD)
            ...(children
                ? String(children).split(',')
                    .filter(age => Boolean(age))
                    .map(ageToParse => getGuestUnit(guestAgeTypes.CHILD, childAgeValidator(ageToParse).value))
                : [])
        ]
    return asHook ? useCallback(fn, []) : fn;
};
