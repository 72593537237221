import { useCallback } from "react";
import { useDispatch, useStore } from 'react-redux';
import { constants } from '@/src/utils/constants';
import {
    geoLocationAction as geoLocationActionAPI,
    getCurrentLocation as getCurrentLoactionAPI,
    getNearLocation as getNearLocationAPI } from '@/redux/actions/globalActions';
import {
    getCityNearbyAttractionsByUserCoordinates as getAttractionsNearbyAPI,
    getStaycationByCountry as getStaycationByCountryAPI
} from '@/redux/services/global';
import { transformCountryNearbyResponse } from "@/utils/globalFunc";
import { currentPositionPromise } from "@/utils/currentPosition";

export default function useApi() {
    const dispatch = useDispatch();
    const store = useStore();

    const getNormalizedCountry = useCallback((country = '') => country.toLowerCase().replace(/\s+/g, ''), []);

    const checkHasLocationData = useCallback((currentLocationData = {}) => Object.entries(currentLocationData).length > 0, []);

    const getCurrentLocation = useCallback(async () => {
        // const currentLocation = store.getState().currentLoc;
        // if (!checkHasLocationData(currentLocation.data)) {
        //     return dispatch(getCurrentLoactionAPI());
        // }
        // return currentLocation;
        const locationResponse = await currentPositionPromise;

        return {
            data: locationResponse.data?.result
        };
    }, [
        dispatch,
        store,
        checkHasLocationData
    ]);

    const withNormalizedLocation = useCallback(fn => currentLocationObject => {
        const normalizedCurrentLocationObject = Object.entries(currentLocationObject).reduce((acc, [key, value]) => ({
            ...acc,
            [key]: value || ''
        }), {});

        if (typeof fn === 'function') {
            return fn(normalizedCurrentLocationObject);
        }

        return normalizedCurrentLocationObject;
    }, []);

    const withLocation = useCallback((fn, checkLocation = true) => async (...args) => {
        const locationResult = await getCurrentLocation();

        if (!checkLocation || checkHasLocationData(locationResult.data)) {
            const normalizedLocationResult = withNormalizedLocation()(locationResult.data);
            return fn(...args, normalizedLocationResult);
        }

        throw new Error('We unable to detect your current location, probably you on the Moon');
    }, [
        getCurrentLocation,
        checkHasLocationData,
        withNormalizedLocation
    ]);

    const getSuggestions = withLocation(async (data, { country, state, city }) =>
        dispatch(geoLocationActionAPI({
            data,
            type: constants.hotel_type,
            country,
            state,
            city
        })));

    const getLocationsNearBy = withLocation(async data => dispatch(getNearLocationAPI(data)));

    const getAttractionsNearby = withLocation(async (onSuccess, onReject, data) => {
        const response = await getAttractionsNearbyAPI({
            ...data,
            // TODO: FE-865 revert
            // country: getNormalizedCountry(data.country)
        });
        if (Array.isArray(response.data)) {
            return onSuccess(response.data);
        }
        onReject(response);
    });

    const getStaycationByCountry = withLocation(async (onSuccess, data) => {
        const response = await getStaycationByCountryAPI(data);

        if (!Array.isArray(response.data)) {
            return;
        }

        onSuccess(transformCountryNearbyResponse(response.data));
    });

    return {
        withNormalizedLocation,
        withLocation,
        getSuggestions,
        getCurrentLocation,
        getLocationsNearBy,
        getAttractionsNearby,
        getStaycationByCountry
    }
}
